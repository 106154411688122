export const removeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036F]/g, '')

export const extractNumeric = (str: string) => str.replace(/\D/g, '')

export const cleanCharsFromString = (value = '', arrOfCharsToBeRemoved: string[] = []) =>
  arrOfCharsToBeRemoved.reduce(
    (prev, cur) => `${prev}`.replace(new RegExp(`\\${cur}`, 'g'), ''),
    value
  )

export const emptyStr = (str: string | null) => {
  if (str === null) return true
  return str.length === 0
}
